import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import styled from 'styled-components';

import { mobile } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';

interface EmailFormProps {
  loading: boolean;
  error: string;
  placeholder: string;
  continueBtnTitle: string;
  onSubmit: (email: string) => void;
  bgColor?: string;
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media ${mobile} {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 26rem;

  @media ${mobile} {
    min-width: 100%;
  }
`;

const Submit = styled(PrimaryButton)`
  min-width: 15rem;
`;

const InputStyled = styled.input<{ bgColor?: string }>`
  border: 0.06rem solid ${({ theme }) => theme.colors.light100};
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.colors.light40};
  border-radius: 0.5rem;
  outline: none;
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.dark80};
  height: 3.63rem;
  padding: 0 1.5rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.dark60};
  }

  @media ${mobile} {
    padding: 0 1rem;
  }
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1rem;
  color: red;
  margin: 0.5rem 0;
`;

const EmailForm: FC<EmailFormProps> = ({
  loading,
  error,
  placeholder = 'Please enter your email',
  continueBtnTitle = 'Continue',
  onSubmit,
  bgColor,
  ...props
}) => {
  const [email, setEmail] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(email);
  };

  return (
    <Container onSubmit={handleSubmit} {...props}>
      <ContentContainer>
        <InputStyled
          name="email"
          type="email"
          placeholder={placeholder}
          disabled={loading}
          required
          onChange={handleChange}
          bgColor={bgColor}
        />
        <Error>{error}</Error>
      </ContentContainer>
      <Submit disabled={loading} type="submit" cornerVariant="round">
        {continueBtnTitle}
      </Submit>
    </Container>
  );
};

export default EmailForm;
